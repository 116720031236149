import { gql, useApolloClient, useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { CalendarIcon, CheckCircleIcon, ChevronLeftIcon, ClockIcon, PencilAltIcon, XCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { DateTime } from "luxon";

import DealForm from "@gcm/components/organisms/DealForm";

import LocationList from "../components/LocationList";

function DealDetailPage() {
  const { business, id } = useParams();

  const [viewState, setViewState] = useState({
    panel: null,
    data: null,
  });

  const { data: viewData, loading, error } = useQuery(gql`
    query DealDetailViewData($id: UUID!) {
      deal(id: $id) {
        id
        name
        active
        plusEnabled
        plusOnly
        isPlusDeal
        plusDeal {
          id
          name
        }
        primaryDeal {
          id
          name
        }
        value
        description
        disclaimer
        startDate
        endDate
        startTime
        endTime
        viewOptions
        business {
          id
          name
          locations {
            id
            name
          }
        }
        locations {
          id
          name
        }
      }
    }
  `, {
    variables: {
      id
    },
    fetchPolicy: "network-only"
  });

  function renderCalendarText(deal) {
    if (!deal.startDate && !deal.endDate) {
      return <div>No restrictions</div>
    }

    let opts = [];
    if (deal.startDate) {
      opts.push(DateTime.fromISO(deal.startDate, { zone: "utc" }).toFormat("M/d/yyyy"));
    } else { opts.push("N/A"); }

    if (deal.endDate) {
      opts.push(DateTime.fromISO(deal.endDate, { zone: "utc" }).toFormat("M/d/yyyy"));
    } else { opts.push("N/A"); }

    return <div>{opts.join(" to ")}</div>
  }

  function renderClockText(deal) {
    if (!deal.startTime && !deal.endTime) {
      return <div>All day</div>
    }

    let opts = [];
    if (deal.startTime) {
      opts.push(DateTime.fromISO(deal.startTime, { zone: "utc" }).toFormat("h:mm a"));
    } else { opts.push("Open"); }

    if (deal.endTime) {
      opts.push(DateTime.fromISO(deal.endTime, { zone: "utc" }).toFormat("h:mm a"));
    } else { opts.push("Close"); }

    return <div>{opts.join(" to ")}</div>
  }

  if (loading) return null;
  if (error) return <div>Error</div>;
  if (!viewData?.deal) return <div>Not found</div>;

  const { deal } = viewData;

  return (
    <>
      <div className="p-12">
        <div className="">
          <Link to={`/business/${business}`} className="flex mb-8 items-center">
            <ChevronLeftIcon className="w-8 h-8 mr-3" />
            <span className="font-bold text-lg">Back to {deal.business.name}</span>
          </Link>
        </div>
        <div className="grid grid-cols-12 mb-8">
          <div className="col-span-7">
            <div>
              <div className="flex flex-row items-center text-sm font-bold">
                {deal.active ?
                  <><CheckCircleIcon color="green" className="w-6 h-6 mr-1" /> Active</> :
                  <><XCircleIcon color="red" className="w-6 h-6 mr-1" /> Inactive</>
                }
              </div>
              <h1>
                {deal.name}
              </h1>
              <div className="flex space-x-2 mb-3">
                {deal.plusOnly && (
                  <span className="pill">GCM+ Only</span>
                )}
                {deal.plusEnabled && (
                  <span className="pill">GCM+ Enabled</span>
                )}
                {deal.isPlusDeal && (
                  <span className="pill">Plus Deal</span>
                )}
                <span className="pill">${deal.value} value</span>
              </div>
              <div className="flex mb-3 items-center">
                <div className="flex items-center mr-3 text-sm">
                  <CalendarIcon className="w-4 h-4 mr-1" />
                  {renderCalendarText(deal)}
                </div>
                <div className="flex items-center text-sm">
                  <ClockIcon className="w-4 h-4 mr-1" />
                  {renderClockText(deal)}
                </div>
              </div>
              <div className="text-sm">
                <span className="font-bold">Description: </span>
                <span>{deal.description || "N/A"}</span>
              </div>
              <div className="text-sm">
                <span className="font-bold">Disclaimer: </span>
                <span>{deal.disclaimer || "N/A"}</span>
              </div>
              {deal.plusEnabled && (
                <div className="text-sm">
                  <span className="font-bold">Plus Deal: </span>
                  <span>{deal.plusDeal ? (deal.plusDeal.id === deal.id ? "2x Redemption" : deal.plusDeal.name) : "2x Redemption"}</span>
                </div>
              )}
              {deal.isPlusDeal && (
                <div className="text-sm">
                  <span className="font-bold">Primary Deal: </span>
                  <span>{deal.primaryDeal ? deal.primaryDeal.name : "Not Set"}</span>
                </div>
              )}

            </div>
          </div>

          <div className="col-span-5 justify-self-end flex items-center">
            <button
              className="transparent"
              onClick={() => {
                setViewState({
                  ...viewState,
                  panel: "FORM"
                })
              }}
            >
              <PencilAltIcon className="w-6 h-6" />
            </button>
          </div>
        </div>

        {!deal.isPlusDeal && (
          <div className="grid grid-cols-12 space-x-5">
            <div className="col-span-8">
              <div className="border border-coolGray rounded-xl shadow-md p-5 bg-white">
                <h3 className="flex justify-between items-center mb-3">
                  Locations
                </h3>
                <LocationList deal={deal.id} locations={deal.locations} business={deal.business} refetchQueries={["DealDetailViewData"]} />
              </div>
            </div>
          </div>
        )}
      </div>

      <SlidingPanel
        type={'right'}
        isOpen={viewState.panel !== null}
        size={30}
      >
        {viewState.panel === "FORM" &&
          <DealForm
            business={deal.business.id}
            data={deal}
            handleClose={() => { setViewState({ ...viewState, panel: null, data: null }) }}
            locations={deal.business.locations}
            onSaved={() => { }}
            refetchQueries={["DealDetailViewData"]}
          />
        }
      </SlidingPanel>
    </>
  )
}

export default DealDetailPage;
