import { ChevronRightIcon } from "@heroicons/react/outline";
import { Color } from "@gcm/libs/tailwind";
import { useNavigate } from "react-router-dom";

function LocationList({ business, locations }) {
  const navigate = useNavigate();

  if (locations.length === 0) {
    return <div>Add a location to get started.</div>
  }

  return (
    <div>
      {locations.map((location, index) => (
        <div
          key={`location-${location.id}`}
          className={`py-4 flex justify-between items-center cursor-pointer ${index + 1 < locations.length ? "border-b border-coolGray" : ""}`}
          onClick={() => {
            navigate(`/business/${business}/location/${location.id}`)
          }}
        >
          <div>
            {location.name}
          </div>
          <div>
            <ChevronRightIcon className="h-6 w-6" color={Color("gray")} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default LocationList;
