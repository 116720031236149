
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import Select from "react-select";
import { gql, useQuery, useApolloClient } from "@apollo/client";
import moment from "moment";

const schema = Yup.object().shape({
  active: Yup.bool(),
  plusEnabled: Yup.bool(),
  plusOnly: Yup.bool(),
  locations: Yup.array(),
  name: Yup.string().required("Name is required").min(2, "2 characters minimum").max(50),
  value: Yup.string().required().test(value => /^\d*[\.{1}\d*]\d*$/.test(value)),
  startDate: Yup.date().nullable(),
  endDate: Yup.date().nullable(),
});

function DealForm({ business, data, handleClose, locations, onSaved, refetchQueries }) {
  const apolloClient = useApolloClient();

  const { data: plusDeals, loading, error } = useQuery(gql`
    query GetAvailablePlusDeals($business: String!) {
      availablePlusDeals(business: $business) {
        id
        plusOnly
        plusEnabled
        isPlusDeal
        name
      }
    }
  `, {
    variables: {
      business
    },
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      let plusDeals = [{ value: null, label: "2x Deal Redemption" }];
      if (data?.plusDeal) plusDeals.push({ value: data.plusDeal.id, label: data.plusDeal.name });
      setViewState({
        ...viewState,
        plusDeals: [
          ...plusDeals,
          ...result.availablePlusDeals.map(deal => ({ value: deal.id, label: deal.name })).filter(deal => deal.value !== data?.id)
        ]
      })
    }
  });

  const [viewState, setViewState] = useState({
    focusedInput: null,
    locations: locations.map(location => ({ value: location.id, label: location.name })),
    plusDeals: []
  });

  async function CreateDeal(business, data, client) {
    try {
      const response = await client.mutate({
        mutation: gql`
        mutation CreateDeal($business: String!, $input: DealInput) {
          createDeal(business: $business, input: $input) {
            id
            name
            active
            plusEnabled
            plusOnly
            isPlusDeal
            value
            description
            disclaimer
            startDate
            endDate
            startTime
            endTime
            viewOptions
            locations {
              id
            }
            business {
              id
            }
          }
        }
      `,
        variables: {
          business,
          input: {
            active: data.active,
            plusEnabled: data.plusEnabled,
            plusOnly: data.plusOnly,
            isPlusDeal: data.isPlusDeal,
            plusDealId: data.plusDeal ? data.plusDeal.value : null,
            name: data.name,
            value: Number(data.value),
            description: data.description,
            disclaimer: data.disclaimer,
            startDate: data.startDate ? DateTime.fromJSDate(data.startDate).toFormat("yyyy-MM-dd") : null,
            endDate: data.endDate ? DateTime.fromJSDate(data.endDate).toFormat("yyyy-MM-dd") : null,
            startTime: data.startTime ? `${data.startTime.utc().add("minutes", data.startTime.utcOffset()).format("HH:mm:00Z")}` : null,
            endTime: data.endTime ? `${data.endTime.utc().add("minutes", data.endTime.utcOffset()).format("HH:mm:00Z")}` : null,
            locations: data.locations.map(location => location["value"]),
            viewOptions_showValue: data.viewOptions_showValue,
            viewOptions_label: data.viewOptions_label,
          }
        },
        refetchQueries: refetchQueries,
        onQueryUpdated(observableQuery) {
          return true;
        }
      });

      return response;
    } catch (err) {
      console.log(err);
      return { error: true }
    }
  }

  async function UpdateDeal(dealId, data, client) {
    try {
      const response = await client.mutate({
        mutation: gql`
        mutation UpdateDeal($id: String!, $input: DealInput) {
          updateDeal(id: $id, input: $input) {
            id
            name
            active
            plusEnabled
            plusOnly
            isPlusDeal
            value
            description
            disclaimer
            startDate
            endDate
            startTime
            endTime
            viewOptions
            locations {
              id
            }
            business {
              id
            }
          }
        }
      `,
        variables: {
          id: dealId,
          input: {
            active: data.active,
            plusEnabled: data.plusEnabled,
            plusOnly: data.plusOnly,
            isPlusDeal: data.isPlusDeal,
            plusDealId: data.plusDeal ? data.plusDeal.value : null,
            name: data.name,
            value: Number(data.value),
            description: data.description,
            disclaimer: data.disclaimer,
            startDate: data.startDate ? DateTime.fromJSDate(data.startDate).toFormat("yyyy-MM-dd") : null,
            endDate: data.endDate ? DateTime.fromJSDate(data.endDate).toFormat("yyyy-MM-dd") : null,
            startTime: data.startTime ? `${moment.utc(data.startTime).add("minutes", data.startTime.utcOffset()).format("HH:mm:00Z")}` : null,
            endTime: data.endTime ? `${moment.utc(data.endTime).add("minutes", data.endTime.utcOffset()).format("HH:mm:00Z")}` : null,
            locations: data.locations.map(location => location["value"]),
            viewOptions_showValue: data.viewOptions_showValue,
            viewOptions_label: data.viewOptions_label,
          }
        },
        refetchQueries: refetchQueries,
        onQueryUpdated(observableQuery) {
          return true;
        }
      });

      return response;
    } catch (err) {
      console.log(err);
      return { error: true }
    }
  }

  if (loading) return <div>Loading...</div>;

  return (
    <div className="relative">
      <div className="sticky left-0 top-0 w-full flex justify-between items-center pl-5 py-3 bg-white z-10">
        <h3>Deal</h3>
        <button className={`transparent`} onClick={() => handleClose()}><XIcon className="h-6 w-6" /></button>
      </div>
      <div className="px-6 pt-1 pb-6">
        <Formik
          initialValues={{
            active: data?.active === undefined ? true : data.active,
            plusEnabled: data?.plusEnabled === undefined ? false : data.plusEnabled,
            plusOnly: data?.plusOnly === undefined ? false : data.plusOnly,
            isPlusDeal: data?.isPlusDeal === undefined ? false : data.isPlusDeal,
            locations: data?.locations ? data.locations.map(location => ({ value: location.id, label: location.name })) : [],
            name: data?.name || "",
            value: data?.value || 0,
            description: data?.description || "",
            disclaimer: data?.disclaimer || "",
            startDate: data?.startDate ? DateTime.fromISO(data.startDate).toJSDate() : null,
            endDate: data?.endDate ? DateTime.fromISO(data.endDate).toJSDate() : null,
            startTime: data?.startTime ? moment(data.startTime).utc() : null,
            endTime: data?.endTime ? moment(data.endTime).utc() : null,
            plusDeal: data?.plusDeal ? { value: data.plusDeal.id, label: data.plusDeal.name } : { value: null, label: "2x Deal Redemption" },
            viewOptions_label: data?.viewOptions?.label || "",
            viewOptions_showValue: data?.viewOptions?.showValue === undefined ? true : data.viewOptions.showValue,
          }}
          validationSchema={schema}
          validateOnMount={true}
          onSubmit={async (values) => {
            let response;
            if (data?.id) {
              response = await UpdateDeal(data.id, values, apolloClient);
            } else {
              response = await CreateDeal(business, values, apolloClient);
            }
            if (!response.error) {
              onSaved();
            }
          }}
        >
          {({ setFieldValue, setValues, isSubmitting, isValid, values }) => (
            <Form>
              <div className="form-group flex mb-2">
                <label className="mr-4">
                  <Field name="active" type="checkbox" className="checkbox" />
                  Active <i className="font-normal ml-2">Select to enable this deal</i>
                </label>
              </div>
              <div className="form-group flex mb-2">
                <label className="mr-4">
                  <Field name="isPlusDeal" type="checkbox" className="checkbox" onChange={(e) => {
                    setFieldValue("isPlusDeal", e.target.checked);
                    if (e.target.checked) {
                      setFieldValue("plusEnabled", false);
                      setFieldValue("plusOnly", false);
                      setFieldValue("locations", []);
                    }
                  }} />
                  Deal Upgrade <i className="font-normal ml-2">Select if this is an upgrade to a regular deal</i>
                </label>
              </div>
              <div className={`mb-5 ${values.isPlusDeal ? "hidden" : ""}`}>
                <div className="mb-2">
                  <label>
                    <Field name="plusOnly" type="checkbox" className="checkbox" disabled={values.isPlusDeal} />
                    Members Only <i className="font-normal ml-2">Select if only available to plus members, ie Golf Deals</i>
                  </label>
                </div>
                <div className="mb-2">
                  <label className="mr-4">
                    <Field name="plusEnabled" type="checkbox" className="checkbox" disabled={values.isPlusDeal} />
                    Upgrade Enabled <i className="font-normal ml-2">Select if deal can be used 2x with plus membership or can have an upgraded deal.</i>
                  </label>
                </div>
              </div>
              <div className={`form-group mb-5 ${values.isPlusDeal || !values.plusEnabled ? "hidden" : ""}`}>
                <label>Plus Deal</label>
                <Select
                  // ref={selectRef}
                  className="select"
                  isClearable
                  options={viewState.plusDeals}
                  closeMenuOnSelect={false}
                  value={values.plusDeal}
                  onChange={(e) => {
                    setFieldValue("plusDeal", e ? e : null);
                  }} />
                <ErrorMessage name="category">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className={`form-group mb-5 ${values.isPlusDeal ? "hidden" : ""}`}>
                <label>Locations</label>
                <Select
                  // ref={selectRef}
                  className="select"
                  options={viewState.locations}
                  isMulti
                  isClearable
                  closeMenuOnSelect={false}
                  value={values.locations}
                  onChange={(e) => {
                    setFieldValue("locations", e ? e : []);
                  }} />
                <ErrorMessage name="category">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="form-group mb-5">
                <label htmlFor="name">Name<em>*</em></label>
                <Field id="name" name="name" placeholder="Deal name" className="input text" maxLength={50} />
                <ErrorMessage name="name">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="grid grid-cols-2 space-x-5 mb-5">
                <div className="form-group">
                  <label htmlFor="name">Value<em>*</em></label>
                  <Field id="value" name="value" placeholder="" className="input text" maxLength={6}
                    onKeyPress={(event) => {
                      if (!/^\d*[\.{1}\d*]\d*$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <ErrorMessage name="value">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
                </div>
              </div>
              <div className="form-group mb-5">
                <label htmlFor="name">Description</label>
                <Field id="description" name="description" placeholder="" className="input text" as="textarea" />
                <ErrorMessage name="description">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="form-group mb-5">
                <label htmlFor="name">Disclaimer</label>
                <Field id="disclaimer" name="disclaimer" placeholder="" className="input text" as="textarea" />
                <ErrorMessage name="disclaimer">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>

              <div className="bg-coolGray p-5 mb-5 rounded">
                <h6 className="mb-3">View Options</h6>
                <div className="form-group mb-5">
                  <label className="mr-4">
                    <Field name="viewOptions_showValue" type="checkbox" className="checkbox" />
                    Show Value <i className="font-normal ml-2">Show value in deal listing</i>
                  </label>
                </div>

                <div className="form-group mb-5">
                  <label htmlFor="name">Label</label>
                  <Field id="viewOptions_label" name="viewOptions_label" placeholder="Listing label" className="input text" maxLength={50} />
                  <ErrorMessage name="viewOptions_label">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
                </div>
              </div>

              <h6>Deal Dates</h6>
              <div className="bg-coolGray p-5 mb-5 rounded">
                <div className="flex">
                  <div className="form-group w-1/3 mr-5">
                    <label htmlFor="startDate">Start Date</label>
                    <DatePicker
                      selected={values.startDate}
                      onChange={(date) => {
                        setFieldValue("startDate", date);
                      }}
                      className="datePicker-input"
                    />
                  </div>
                  <div className="form-group w-1/3">
                    <label htmlFor="endDate">End Date</label>
                    <DatePicker
                      selected={values.endDate}
                      onChange={(date) => setFieldValue("endDate", date)}
                      className="datePicker-input"
                    />
                  </div>
                </div>
              </div>

              <h4>Daily Time Limits</h4>
              <div className="bg-coolGray p-5 mb-5 rounded">
                <div className="flex">
                  <div className="form-group w-1/3 mr-5">
                    <label htmlFor="startDate">Start Time</label>
                    <TimePicker
                      defaultValue={values.startTime}
                      showSecond={false}
                      use12Hours
                      minuteStep={15}
                      onChange={(val) => {
                        setFieldValue("startTime", val);
                      }}
                    />
                  </div>
                  <div className="form-group w-1/3">
                    <label htmlFor="endDate">End Time</label>
                    <TimePicker
                      defaultValue={values.endTime}
                      showSecond={false}
                      use12Hours
                      minuteStep={15}
                      onChange={(val) => {
                        setFieldValue("endTime", val);
                      }}
                    />
                  </div>
                </div>
              </div>

              <button disabled={isSubmitting || !isValid} type="submit" className={`button ${(isSubmitting || !isValid) && "opacity-75"}`}>Save</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

DealForm.defaultProps = {
  locations: [],
  refetchQueries: [],
}

export default DealForm;
