import { gql, useQuery, useApolloClient } from "@apollo/client";
import { ChartBarIcon, CheckIcon, PlusIcon } from "@heroicons/react/outline";
import { Color } from "@gcm/libs/tailwind";

function DealList({ locationId, business, selected, refetchQueries }) {
  const apolloClient = useApolloClient();

  const { data, loading, error } = useQuery(gql`
    query GetBusinessDeals($business: String!, $plusDeals: Boolean) {
      deals(business: $business, plusDeals: $plusDeals) {
        id
        plusOnly
        plusEnabled
        isPlusDeal
        name
      }
    }
  `, {
    variables: {
      business,
      plusDeals: false,
    },
    fetchPolicy: "network-only"
  });

  function renderActionButton(deal) {
    const isSelected = selected.find(d => d.id === deal.id);
    if (isSelected) return (
      <button
        className="transparent px-1 flex"
        onClick={async () => {
          const removeResponse = await apolloClient.mutate({
            mutation: gql`
              mutation RemoveDealLocation($deal: String!, $location: String!) {
                removeDealLocation(deal: $deal, location: $location) {
                  id
                  locations {
                    id
                  }
                }
              }
            `,
            variables: {
              deal: deal.id,
              location: locationId
            },
            refetchQueries: refetchQueries,
            onQueryUpdated(observableQuery) {
              return true;
            }
          })
        }}
      >
        <div className="border-2 border-blue rounded">
          <CheckIcon className="w-4 h-4" color={Color("blue")} />
        </div>
      </button>
    );

    return (
      <button className="transparent px-1"
        onClick={async () => {
          const addResponse = await apolloClient.mutate({
            mutation: gql`
              mutation AddDealLocation($deal: String!, $location: String!) {
                addDealLocation(deal: $deal, location: $location) {
                  id
                  locations {
                    id
                  }
                }
              }
            `,
            variables: {
              deal: deal.id,
              location: locationId
            },
            refetchQueries: refetchQueries,
            onQueryUpdated(observableQuery) {
              return true;
            }
          })
        }}
      >
        <div className="border-2 border-coolGray rounded">
          <CheckIcon className="w-4 h-4" color={Color("transparent")} />
        </div>
      </button>
    );
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error!</div>;
  if (!data) return <div>No data!</div>;

  return (
    <div className="">
      {
        data.deals.length === 0 ? (
          <div>Add a deal to enable it for this location.</div>
        ) : (
          <>
            {data.deals.map((deal, index) => {
              return (
                <div key={`deal-${deal.id}`} className={`flex justify-between items-center py-2 ${index + 1 < data.deals.length ? "border-b border-coolGray" : ""}`}>
                  <div className="w-1/12 flex justify-start">
                    {renderActionButton(deal)}
                  </div>
                  <div className="w-10/12">
                    {deal.name}
                  </div>
                  <div className="w-1/12 flex justify-center">
                    <button className="transparent px-1">
                      <ChartBarIcon className="w-6 h-6" />
                    </button>
                  </div>
                </div>
              )
            })}
          </>
        )
      }
    </div>
  )
}

DealList.defaultProps = {
  refetchQueries: [],
}

export default DealList;
