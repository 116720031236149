import { ChevronRightIcon } from "@heroicons/react/outline";
import { Color } from "@gcm/libs/tailwind";
import { useNavigate } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import { forwardRef, useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

function DealList({ business, plusDeals, onItemPress }) {
  const navigate = useNavigate();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [items, setItems] = useState([]);
  const [viewState, setViewState] = useState({
    saveSort: false,
    plusDeals: false,
    active: true,
  });

  const { data, loading, error } = useQuery(gql`
    query GetBusinessDeals($business: String!, $plusDeals: Boolean, $active: Boolean) {
      deals(business: $business, plusDeals: $plusDeals, active: $active) {
        id
        plusOnly
        plusEnabled
        isPlusDeal
        name
      }
    }
  `, {
    variables: {
      business,
      plusDeals: viewState.plusDeals,
      active: viewState.active,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setItems(data.deals.map(deal => deal));
    }
  });

  const [
    saveSort,
    { data: saveSortData, loading: saveSortLoading, error: saveSortError }] =
    useMutation(gql`
      mutation SaveDealSort($input: SortedDealInput!) {
        saveDealSort(input: $input) {
          id
          sortOrder
        }
      }
    `);

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });

      setViewState({
        ...viewState,
        saveSort: true,
      });
    }
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Unable to load deals.</div>;

  return (
    <div>
      <div className="mb-2">
        <input type="checkbox" checked={viewState.active} onChange={() => setViewState({ ...viewState, active: !viewState.active })} /> <span className="pl-2">Active Deals</span>
      </div>
      <div className="">
        <a href="#"
          className={`${!viewState.plusDeals ? "border-b no-underline hover:no-underline focus:no-underline" : ""}`}
          onClick={e => {
            e.preventDefault();
            setViewState({ ...viewState, plusDeals: false });
          }}>Primary Deals</a>{" | "}
        <a href="#"
          className={`${viewState.plusDeals ? "border-b no-underline hover:no-underline focus:no-underline" : ""}`}
          onClick={e => {
            e.preventDefault();
            setViewState({ ...viewState, saveSort: false, plusDeals: true });
          }}>GCM+ Deals</a>
      </div>
      {items.length > 0 ? (
        <>
          {viewState.saveSort && (
            <div className="py-2">
              <button
                className="w-full rounded-lg text-white"
                onClick={() => {
                  saveSort({
                    variables: { input: { sortedDeals: items.map((el, index) => ({ sortOrder: index, id: el.id })) } }
                  }).then(() => {
                    setViewState({
                      ...viewState,
                      saveSort: false,
                    });
                  });
                }}
              >
                Save Sort
              </button>
            </div>
          )
          }
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              <div className="dealTable">
                {items.map((deal, index) => (
                  <SortableItem
                    key={`deal-${deal.id}`}
                    id={deal.id}
                    deal={deal}
                    business={business}
                    plusDeals={viewState.plusDeals}
                  />
                ))}
              </div>
            </SortableContext>
          </DndContext>
        </>
      ) : (
        <div>Add a deal to get started.</div>
      )}

    </div>
  )
}

function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const navigate = useNavigate();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { business, deal, id, plusDeals } = props;

  return (
    <div
      className="flex"
      ref={setNodeRef} style={style}
    >
      {plusDeals === false && (
        <div className="w-6 mr-4"{...attributes} {...listeners} id={id}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </div>
      )}

      <div
        className="cursor-pointer flex-1"
        onClick={() => {
          navigate(`/business/${business}/deal/${deal.id}`);
        }}
      >
        <div className={`flex justify-between items-center`}>
          <div>
            {deal.name}
          </div>
          <div>
            <ChevronRightIcon className="h-6 w-6" color={Color("gray")} />
          </div>
        </div>
        <div className="flex space-x-2">
          {deal.plusOnly && (
            <span className="pill">GCM+ Only</span>
          )}
          {deal.plusEnabled && (
            <span className="pill">GCM+ Enabled</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default DealList;
